import React, { Component } from "react";
import {
  Collapse,
  List,
  Form,
  Button,
  Row,
  Col,
  Typography,
  Input,
  notification,
  Modal,
  Descriptions,
  Spin,
  Tooltip,
  DatePicker,
  Select,
} from "antd";
import { userStore } from "../../storage/user/User";
import IssueSelector, { Issue } from "../../components/issues/IssueSelector";
import { Jira } from "../../services";
import { observer } from "mobx-react";
import "./WorklogsPage.css";
import StatusSelector, {
  Transition,
} from "../../components/status/StatusSelector";
import ms from "ms";
import prettyms from "pretty-ms";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import Text from "../../utils/Text";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import ProjectSelector from "../../components/projects/ProjectSelector";
import moment from "moment";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import UserSelector from "../../components/users/UserSelector";
import { WorklogResumee } from "../../components/worklogs/WorklogResumee";
import Paragraph from "antd/lib/typography/Paragraph";

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;

const mdParser = new MarkdownIt(/* Markdown-it options */);

export const FOUND_PROBLEMS_TASK = "INT-6601";
export const NEXT_DAY_TASK = "INT-6600";

type SavedWorklog = {
  isNew?: boolean;
  loading?: boolean;
  uploaded: boolean;
  selectedIssue: Issue;
  selectedEpic: Issue;
  timeSpent: string;
  worklogDescription: string;
  selectedTransition: Transition | undefined;
};
@observer
export default class WorklogsPage extends Component {
  state: {
    userSearchbox: string;
    selectedUser: any | null;
    epicSearchbox: "";
    isNew: boolean;
    issueSearchbox: "";
    modals: {
      [key: string]: {
        visible: boolean;
        fields: {
          [key: string]: any;
        };
      };
    };
    exceptions: string[];
    loading: boolean;
    savedWorklogs: SavedWorklog[];
    selectedIssue: Issue;
    selectedEpic: Issue;
    timeSpent: string;
    worklogDescription: string;
    selectedTransition: Transition | undefined;
    transitions: Transition[];
    macros: any[];
  };

  mainIssueSelector: React.RefObject<IssueSelector>;
  nextDayIssueSelector: React.RefObject<IssueSelector>;
  worklogResumee: React.RefObject<WorklogResumee>;

  constructor(props: any) {
    super(props);

    this.mainIssueSelector = React.createRef<IssueSelector>();
    this.worklogResumee = React.createRef<WorklogResumee>();
    this.nextDayIssueSelector = React.createRef<IssueSelector>();

    this.state = {
      userSearchbox: "",
      selectedUser: null,
      selectedIssue: {} as Issue,
      issueSearchbox: "",
      epicSearchbox: "",
      timeSpent: "",
      worklogDescription: "",
      isNew: false,
      selectedTransition: undefined,
      selectedEpic: {} as Issue,
      loading: false,
      savedWorklogs: [] as SavedWorklog[],
      exceptions: [],
      transitions: [],
      modals: {
        createTask: {
          visible: false,
          fields: {},
        },
        nextDayTasks: {
          visible: false,
          fields: {
            selection: [],
          },
        },
        foundProblems: {
          visible: false,
          fields: {
            tasks: [],
          },
        },
        worklogResumee: {
          visible: false,
          fields: {},
        },
        macros: {
          visible: false,
          fields: {},
        },
      },
      macros: [],
    };
  }

  componentDidMount = async () => {
    try {
      const preferences = JSON.parse(
        localStorage.getItem("preferences") || "{}"
      );
      const macros = JSON.parse(localStorage.getItem("macros") || "[]");

      if (preferences?.enabledPersistedState) {
        const persistedState = JSON.parse(
          localStorage.getItem("persistedState") || "{}"
        );
        this.setState(persistedState);
      }

      if (macros?.length < 1) {
        macros.push({
          name: "Imputación Organizada",
          text: `
**Avances en el día de hoy:**


**En qué punto estamos:**


**Qué es lo que queda pendiente:**`,
        });
        localStorage.setItem("macros", JSON.stringify(macros));
      }

      this.setState({ macros });
    } catch (error) {
      console.log(error);
    }

    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "¿Estás seguro de que deseas cerrar esta ventana? Podrías tener cambios sin subir.";
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any, snapshot: any) => {
    try {
      const preferences = JSON.parse(
        localStorage.getItem("preferences") || "{}"
      );
      if (preferences?.enabledPersistedState) {
        localStorage.setItem("persistedState", JSON.stringify(this.state));
      }
    } catch (error) { }
  };

  onSelectedIssue = async (selectedIssue: Issue) => {
    let epicKey =
      selectedIssue.fields.customfield_10008 ||
      selectedIssue.fields.parent?.key;
    let epicName = selectedIssue.fields.parent?.fields.summary
      ? `${epicKey} - ${selectedIssue.fields.parent?.fields.summary}`
      : epicKey;
    let selectedEpic = { key: epicKey };

    /**
     * Fetch the complete issue to get time estimation
     */
    this.deferred(async () => {
      this.setState({ loading: true });
      const completeIssue = await Jira.getResource(
        `issue/${selectedIssue.key}`
      );
      selectedIssue.fields.original = completeIssue;
      this.setState({
        issueSearchbox: selectedIssue.key,
        selectedIssue,
        isNew: false,
        loading: false,
      });
    });

    this.setState({ selectedIssue, selectedEpic, epicSearchbox: epicName });
  };

  async deferred(cb: () => Promise<void>) {
    await cb();
  }

  onIssuesLoaded = (type: "epics" | "issues", data: Issue[]) => {
    if (type === "epics") {
      const selectedEpic = data.find(
        (i) => i.key === this.state.selectedEpic.key
      );
      if (selectedEpic) {
        this.setState({
          selectedEpic,
          epicSearchbox: `${selectedEpic.key} - ${selectedEpic.fields.summary}`,
        });
      }
    }
  };

  onSelectedEpic = (selectedEpic: Issue) =>
    this.setState({
      selectedEpic,
      epicSearchbox: `${selectedEpic.key} - ${selectedEpic.fields.summary}`,
    });

  onSelectedTransition = (selectedTransition: Transition | undefined) =>
    this.setState({ selectedTransition });

  setSelectedIssueProperty = (property: string, value: any) => {
    let { selectedIssue } = this.state;
    if (selectedIssue && selectedIssue.key) {
      selectedIssue.fields[property] = value;
    }
    this.setState({ selectedIssue });
  };

  validate = async () => {
    let { selectedIssue, selectedEpic, selectedTransition } = this.state;

    if (!selectedIssue || !selectedIssue.fields) return;

    let validationRules = [
      {
        name: "EMPTY_EPIC",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          return (
            (selectedIssue?.fields?.customfield_10008 || selectedEpic?.key) &&
            this.state.epicSearchbox
          );
        },
        message: "Por favor, coloca un epic en la tarea.",
        fallback: () => { },
      },
      {
        name: "NON_CLOSED_EPIC",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          return (
            selectedIssue?.fields?.customfield_10008 !== undefined &&
            selectedIssue?.fields?.customfield_10008 !== "" &&
            !selectedEpic?.fields?.summary.toUpperCase().includes("CERRADO") &&
            selectedEpic?.fields?.status.id !== 6 &&
            selectedEpic?.fields?.status.id !== 10467
          );
        },
        message: "",
        fallback: () => {
          return new Promise((resolve, reject) => {
            confirm({
              title: "El epic de esta tarea está cerrado.",
              content: "¿Quieres subir el worklog de todas formas?",
              okText: "Sí",
              cancelText: "No",
              onOk: () => {
                resolve(true);
              },
              onCancel() {
                reject();
              },
            });
          });
        },
      },
      {
        name: "NO_TEXT",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition
        ) => {
          return this.state.timeSpent
            ? this.state.worklogDescription !== ""
            : true;
        },
        message: "Por favor, introduzca texto en el worklog.",
        fallback: () => { },
      },
      {
        name: "MANDATORY_STATUS",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition
        ) => {
          return (
            transition.to?.id &&
            transition.to?.id !== "" &&
            transition.id !== "CONTINUE"
          );
        },
        message: "",
        fallback: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          return new Promise((resolve, reject) => {
            if (transition?.id === "CONTINUE") {
              confirm({
                title: "Espera...",
                content:
                  "¿Estás seguro de que quieres mantener el estado de la tarea?",
                onOk: () => {
                  resolve(true);
                },
                onCancel() {
                  reject();
                },
              });
            }
          });
        },
      },
      {
        name: "DONT_KEEP_OPEN",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition
        ) => {
          if (transition.id === "CONTINUE") {
            return true;
          }

          let transitionValid = transition?.to?.id
            ? this.state.worklogDescription !== ""
              ? transition?.to?.id !== "1" || transition?.to?.id !== 1
              : true
            : !transition?.to?.id &&
            this.state.worklogDescription !== "" &&
            selectedIssue.fields.status.id !== "1";

          return transitionValid;
        },
        message: "",
        fallback: () => {
          return new Promise((resolve, reject) => {
            confirm({
              title: 'El estado de esta tarea no debería ser "Abierto"',
              content: '¿Quieres cambiar el estado a "En progreso"?',
              cancelText: "Agregarlo de todas formas",
              okText: 'Cambiar a "En Progreso"',
              onOk: () => {
                const inProgressTransition = this.state.transitions.find(
                  (transition) => transition.to.id === "3"
                );
                this.setState({
                  selectedTransition: { ...inProgressTransition },
                });
                resolve(true);
              },
              onCancel() {
                resolve(true);
              },
            });
          });
        },
      },

      {
        name: "INVALID_TIME_SPENT",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          return !transition?.id
            ? this.state.timeSpent &&
            this.state.timeSpent.split(" ").every((tl: string) => ms(tl))
            : true;
        },
        message: "El tiempo dedicado es inválido. Por favor, corrigelo.",
        fallback: () => { },
      },
      {
        name: "NO_ESTIMATION_DEFAULT",
        rule: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          if (
            this.state.timeSpent &&
            this.state.timeSpent !== "" &&
            this.state.timeSpent !== undefined
          ) {
            return (
              this.state.timeSpent !== "" &&
              this.state.timeSpent !== undefined &&
              selectedIssue.fields.original?.fields.timetracking
                .originalEstimate !== undefined &&
              selectedIssue.fields.original?.fields.timetracking
                .originalEstimate !== ""
            );
          }

          return true;
        },
        fallback: (
          selectedIssue: Issue,
          selectedEpic: Issue,
          transition: Transition | undefined
        ) => {
          return new Promise((resolve, reject) => {
            confirm({
              title: "La tarea no tiene estimación.",
              content: "¿Quieres copiar el tiempo dedicado en la estimación?",
              onOk: () => {
                selectedIssue.fields.original.fields.timetracking.originalEstimate =
                  this.state.timeSpent;
                this.forceUpdate();
                resolve(true);
              },
              onCancel() {
                reject();
              },
            });
          });
        },
      },
    ];

    const invalidRules = validationRules.filter(
      (vRule) =>
        !vRule.rule(
          selectedIssue,
          selectedEpic,
          selectedTransition || ({} as Transition)
        )
    );

    if (invalidRules.length > 0) {
      let canContinue = true;
      for (const rule of invalidRules) {
        if (!rule.message || rule.message === "") {
          try {
            await rule.fallback(
              selectedIssue,
              selectedEpic,
              selectedTransition
            );
          } catch (err) {
            canContinue = false;
            break;
          }
        } else if (rule.message && rule.message !== "") {
          this.openNotification("Error", rule.message);
          canContinue = false;
          break;
        }
      }

      if (!canContinue) {
        return;
      }
    }

    this.saveWorklog();
  };

  saveWorklog = async () => {
    const {
      selectedIssue,
      selectedEpic,
      selectedTransition,
      worklogDescription,
      timeSpent,
      isNew,
    } = this.state;
    let savedWorklogs = this.state.savedWorklogs;
    savedWorklogs.push({
      loading: false,
      uploaded: false,
      selectedIssue,
      selectedEpic,
      selectedTransition,
      worklogDescription,
      timeSpent,
      isNew,
    });
    this.setState({
      isNew: false,
      savedWorklogs,
      selectedIssue: {},
      selectedEpic: {},
      selectedTransition: {},
      worklogDescription: "",
      timeSpent: "",
      issueSearchbox: "",
    });
  };

  /**
   * _.uploadWorklogs
   * It will upload all the worklogs one by one.
   */
  uploadWorklogs = async () => {
    this.setState({ loading: true });
    const internalIssuesUploaded = await this.uploadInternalTasks();
    if (!internalIssuesUploaded) {
      this.setState({ loading: false });
      return;
    }

    /**
     * Increased upload speed by doing a promise.all.
     */

    try {
      let operations = this.state.savedWorklogs.map((wl, index) =>
        this.uploadWorklog(wl, index, false)
      );
      await Promise.all(operations);
      notification.open({
        type: "success",
        message: "La operación ha sido completada con éxito.",
      });

      this.worklogResumee.current?.loadResumee();
    } catch (err) {
      console.error(err);
    } finally {
      await this.mainIssueSelector.current?.updateEpics();
      this.setState({ loading: false });
    }
  };

  uploadWorklog = async (
    savedWorklog: SavedWorklog,
    index: number,
    handleLoading: boolean = true
  ) => {
    let {
      isNew,
      selectedIssue,
      selectedEpic,
      selectedTransition,
      worklogDescription,
      timeSpent,
    } = savedWorklog;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let createdIssue, response;
    try {
      handleLoading && this.setState({ loading: true });
      // Step 1. Save or create the issue!
      // 1.a - Simple update of static fields

      if (!isNew) {
        await Jira.putResource(`issue/${selectedIssue.key}`, {
          fields: {
            summary: selectedIssue.fields.summary,
            description: selectedIssue.fields.description,
            customfield_10008: selectedEpic.key,
          },
        });
      } else {
        createdIssue = await Jira.postResource("issue", {
          fields: {
            summary: selectedIssue.fields.summary,
            description: selectedIssue.fields.description,
            customfield_10008: selectedEpic.key,
            project: {
              key: selectedIssue.fields.project.key,
            },
            issuetype: {
              id: "3",
            },
            assignee: {
              accountId: userStore.user.accountId,
            },
          },
        });
      }

      if (!createdIssue?.key && isNew) {
        // eslint-disable-next-line no-throw-literal
        throw { err: "The issue has not been created. It's debugging time!" };
      }

      const issueKey = isNew ? createdIssue.key : selectedIssue.key;

      const timeTracking = [
        {
          edit: {
            originalEstimate:
              savedWorklog.selectedIssue.fields.original?.fields?.timetracking
                ?.originalEstimate,
          },
        },
      ];

      await Jira.putResource(`issue/${issueKey}`, {
        update: {
          timetracking: timeTracking,
        },
      });

      if (
        worklogDescription &&
        worklogDescription !== "" &&
        timeSpent &&
        timeSpent !== ""
      ) {
        // Step 3. Upload the worklog

        let comment = Text.mdToJira(worklogDescription)
          ? Text.mdToJira(worklogDescription)
          : worklogDescription;
        comment = comment.trim();
        let dto = {
          comment: {
            type: "doc",
            version: 1,
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: comment,
                    type: "text",
                  },
                ],
              },
            ],
          },
          started: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
          timeSpent,
        };

        response = await Jira.postResource(`issue/${issueKey}/worklog`, dto);
      }

      // Step 2. Do transition
      if (
        selectedTransition &&
        selectedTransition.id &&
        selectedTransition.id !== "CONTINUE"
      ) {
        response = await Jira.postResource(
          `issue/${issueKey}/transitions`,
          {
            update: {
              comment: [
                {
                  add: {
                    body: `${selectedTransition.name}`,
                  },
                },
              ],
            },
            transition: {
              id: selectedTransition.id,
            },
          },
          "expand=transitions.fields"
        );
      }

      await this.setState({
        savedWorklogs: this.state.savedWorklogs.filter((i, _index) => {
          return isNew ? _index !== index : i.selectedIssue.key !== issueKey;
        }),
      });
    } catch (e) {
      this.openNotification(
        "Error",
        "Ha ocurrido un error al subir el worklog."
      );
      console.error(e);
    } finally {
      handleLoading && this.setState({ loading: false });
    }
  };

  openNotification = (title: string, desc: string, icon?: Component) => {
    notification.open({
      message: title,
      description: desc,
      icon,
    });
  };

  setModalField(modalName: string, field: string, value: any) {
    let { modals } = this.state;
    if (!modals[modalName]) return;
    modals[modalName].fields[field] = value;
    // ! Danger
    this.forceUpdate();
  }

  toggleModal = (modalName: string) => {
    let modals = this.state.modals;

    if (!modals[modalName]) {
      console.log(`${modalName} does not exists.`);
      return;
    }

    modals[modalName].visible = !modals[modalName].visible;

    this.setState({
      modals: modals,
    });
  };

  render() {
    const { modals } = this.state;
    return (
      <div className="mt-4">
        {this.renderNewTaskModal(modals)}
        {this.renderMacrosModal(modals)}
        <Collapse className="mt16">
          {this.renderWorklogCreation()}
          {this.renderNextDay(modals)}
          {this.renderFoundProblems(modals)}
          {this.renderWorklogResumee()}
        </Collapse>
      </div>
    );
  }

  refresh = async (panel: string) => {
    try {
      if (panel === "worklogCreation") {
        await this.mainIssueSelector?.current?.updateEpics();
        notification.open({
          type: "success",
          message: "El selector de tareas ha sido actualizado.",
        });
      } else if (panel === "worklogResumee") {
        await this.worklogResumee?.current?.loadResumee();
        notification.open({
          type: "success",
          message: "El resumen de tareas ha sido actualizado.",
        });
      } else if (panel === "nextDayTasks") {
        await this.nextDayIssueSelector?.current?.updateEpics();
        notification.open({
          type: "success",
          message: "Las tareas del próximo día han sido actualizadas.",
        });
      }
    } catch (error) {
      notification.open({
        type: "error",
        message:
          "Ha ocurrido un error al intentar actualizar. Por favor, contacte con un administrador.",
      });
    }
  };

  uploadModifiedTime = async (item: any) => {
    const worklogId = item.worklogs[0].id;
    const valid = ms(item.totalTime);

    if (valid) {
      let dto = {
        timeSpent: item.totalTime,
      };

      try {
        await Jira.putResource(
          `issue/${NEXT_DAY_TASK}/worklog/${worklogId}`,
          dto
        );
        await this.worklogResumee?.current?.loadResumee();
        this.setModalField("worklogResumee", "selectedItem", {});
        notification.open({
          type: "success",
          message: `Se ha actualizado el tiempo imputado de la tarea ${item.key}`,
        });

        return true;
      } catch (error) {
        console.error(error);
        notification.open({
          message: `Ha ocurrido un error al intentar modificar el worklog de la tarea ${item.key}.`,
          type: "error",
        });
        return false;
      }
    } else {
      notification.open({
        type: "error",
        message: "Por favor, introduzca un tiempo valido.",
      });
    }
  };

  renderWorklogResumee = () => {
    let {
      modals: {
        worklogResumee: { fields },
      },
    } = this.state;
    return (
      <Panel key="worklogResumee" header="Resumen">
        <Row gutter={16}>
          <Col span={24}>
            <Tooltip title="Actualizar">
              <Button
                className="flex-center"
                shape="circle"
                icon={<RedoOutlined />}
                onClick={() => this.refresh("worklogResumee")}
              />
            </Tooltip>
          </Col>
          <Col span={12} className="mt16">
            <DatePicker
              defaultValue={moment(new Date())}
              value={fields.worklogDate}
              onChange={(evt) =>
                this.setModalField("worklogResumee", "worklogDate", evt)
              }
            />
          </Col>
          <Col span={12} className="mt16">
            <Button>Total: {fields.totalTime || prettyms(0)}</Button>
          </Col>
          <Col span={12} className="mt16">
            <WorklogResumee
              ref={this.worklogResumee}
              onLoad={(resumee) => {
                this.setModalField(
                  "worklogResumee",
                  "totalTime",
                  resumee.totalTime
                );
              }}
              date={fields.worklogDate}
              onSelect={(item) =>
                this.setModalField("worklogResumee", "selectedItem", item)
              }
            />
          </Col>
          {fields.selectedItem?.key && (
            <Col span={12} className="mt16">
              <Paragraph>Detalles</Paragraph>
              <Descriptions bordered layout="vertical">
                <Descriptions.Item label="Identificador">
                  {fields.selectedItem.key}
                </Descriptions.Item>
                <Descriptions.Item label="Titulo">
                  {fields.selectedItem.summary}
                </Descriptions.Item>
                <Descriptions.Item label="Tiempo imputado">
                  <Input
                    className="flush-ant-input"
                    value={fields.selectedItem.totalTime}
                    onChange={(evt) => {
                      fields.selectedItem.totalTime = evt.target.value;
                      fields.selectedItem.changedTime = true;
                      this.forceUpdate();
                    }}
                  />

                  {fields.selectedItem.changedTime ? (
                    <Button
                      type="dashed"
                      onClick={() =>
                        this.uploadModifiedTime(fields.selectedItem)
                      }
                    >
                      Validar y guardar
                    </Button>
                  ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Imputación">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fields.selectedItem.worklogs.reduce(
                        (prev: any, curr: any, idx: number) =>
                          prev +
                          `
                                ${mdParser.render(curr.comment || "")}`,
                        ""
                      ),
                    }}
                  ></div>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}
        </Row>
      </Panel>
    );
  };

  renderWorklogCreation = () => {
    const {
      savedWorklogs,
      loading,
      selectedIssue,
      selectedEpic,
      timeSpent,
      worklogDescription,
    } = this.state;
    const canValidateWorklog = !!(
      loading &&
      selectedEpic &&
      selectedEpic.key &&
      selectedEpic.fields &&
      selectedEpic.fields.summary &&
      selectedIssue &&
      selectedIssue.key &&
      selectedIssue.fields &&
      selectedIssue.fields.summary
    );

    return (
      <Panel key="createWorklog" header="Imputar">
        <Row>
          <Col span={24}>
            <Tooltip title="Actualizar">
              <Button
                className="flex-center"
                shape="circle"
                icon={<RedoOutlined />}
                onClick={() => this.refresh("worklogCreation")}
              />
            </Tooltip>
          </Col>
          <Col span={24} className="mv16">
            <UserSelector
              project="JIFC"
              mode={"search"}
              exceptions={[userStore.user.accountId]}
              onInputChanged={(text) => {
                if (!text) {
                  this.setState({ userSearchbox: "", selectedUser: null });
                  return;
                }

                this.setState({ userSearchbox: text });
              }}
              onSelect={(user) => {
                this.setState({
                  selectedUser: user,
                  userSearchbox: user.displayName,
                });
              }}
              value={this.state.userSearchbox}
            />
          </Col>
          <Col span={20}>{this.renderIssueSelector(selectedIssue)}</Col>
          {!selectedIssue ||
            (!selectedIssue?.key && (
              <Col span={4} className="flex-center">
                <Tooltip title="Crear tarea">
                  <Button
                    onClick={() => this.toggleModal("createTask")}
                    type="primary"
                    shape="circle"
                    className="flex-center"
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </Col>
            ))}
        </Row>
        {/** Issue modification and worklog edition */}
        {selectedIssue?.key && (
          <>
            <Row>
              <Col xs={24} md={12}>
                <Row>
                  <Col span={24} className="form-column">
                    <Input
                      value={selectedIssue.fields.summary}
                      onChange={(evt) =>
                        this.setSelectedIssueProperty(
                          "summary",
                          evt.target.value
                        )
                      }
                      placeholder={"Titulo de la incidencia..."}
                    />
                  </Col>
                  <Col span={24} className="form-column">
                    <TextArea
                      placeholder="Descripción de la tarea..."
                      value={
                        selectedIssue.fields.description?.content[0]?.content[0]
                          .text
                      }
                      rows={1}
                      onChange={(evt) =>
                        this.setSelectedIssueProperty(
                          "description",
                          evt.target.value
                        )
                      }
                    />
                  </Col>
                  <Col span={24} className="form-column">
                    <IssueSelector
                      mode="search"
                      value={this.state.epicSearchbox}
                      placeholder={"Epic link..."}
                      exceptions={savedWorklogs.map((i) => i.selectedIssue.key)}
                      type="epics"
                      project={selectedIssue.fields.project.key}
                      onSelect={this.onSelectedEpic}
                      onLoad={(epics) => this.onIssuesLoaded("epics", epics)}
                      onInputChanged={(text) =>
                        this.setState({ epicSearchbox: text })
                      }
                    />
                  </Col>
                  <Col span={24} className="form-column">
                    {this.canShowStatusSelector(selectedIssue) && (
                      <StatusSelector
                        issueKey={selectedIssue.key}
                        isNew={this.state.isNew}
                        placeholder="Subir como..."
                        currentStatusName={selectedIssue.fields.status.name}
                        onSelected={this.onSelectedTransition}
                        onLoad={(loadedTransitions) =>
                          this.setState({ transitions: loadedTransitions })
                        }
                      />
                    )}
                  </Col>
                  <Col span={24} className="form-column">
                    <Input
                      placeholder="Tiempo estimado (Ej. 1w)"
                      value={
                        selectedIssue.fields.original?.fields.timetracking
                          ?.originalEstimate
                      }
                      onChange={(evt) => {
                        let timeTracking =
                          selectedIssue.fields.original?.fields.timetracking;

                        if (!timeTracking) {
                          return;
                        }

                        timeTracking.originalEstimate = evt.target.value;
                        selectedIssue.fields.original.fields.timetracking =
                          timeTracking;
                        this.setState({ selectedIssue });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12} className="mh100">
                <Row className="mh100">
                  <Col span={24} className="form-column">
                    <Input
                      placeholder="Tiempo dedicado (Ej. 1h 30m)"
                      value={timeSpent}
                      onChange={(evt) =>
                        this.setState({ timeSpent: evt.target.value })
                      }
                    />
                  </Col>
                  <Col span={24} className="form-column mh100">
                    {this.renderWorklogEditor(worklogDescription)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  loading={loading}
                  disabled={canValidateWorklog}
                  onClick={this.validate}
                  className="form-button"
                  type="primary"
                >
                  Validar y guardar
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt16">
          <Col md={12} xs={24}>
            <Button
              disabled={savedWorklogs.length < 1}
              onClick={() => this.uploadWorklogs()}
              type="primary"
            >
              Subir todo
            </Button>
          </Col>
          <Col md={12} xs={24}>
            {savedWorklogs.length > 0 && (
              <Col>
                <Button>Total: {this.getTotalTime(savedWorklogs)}</Button>
              </Col>
            )}
          </Col>
          <Col span={24} className="mt16">
            {this.renderSavedWorklogs(savedWorklogs)}
          </Col>
        </Row>
      </Panel>
    );
  };

  asyncConfirm = (params: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      confirm({
        ...params,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  uploadFoundProblems = async () => {
    const {
      modals: {
        foundProblems: {
          fields: { text: foundProblems },
        },
      },
    } = this.state;
    let foundProblemsText,
      canContinue = true;

    this.setModalField("foundProblems", "loading", true);
    if (!foundProblems) {
      canContinue = await this.asyncConfirm({
        title: "No has logueado problemas encontrados.",
        okText: "No tuve problemas hoy",
        cancelText: "Cancelar",
      });
    }

    if (!canContinue) {
      return false;
    }

    foundProblemsText = !foundProblems ? "Sin problemas." : foundProblems;
    foundProblemsText = foundProblemsText.trim();
    let dto = {
      comment: {
        type: "doc",
        version: 1,
        content: [
          {
            type: "paragraph",
            content: [
              {
                text: foundProblemsText,
                type: "text",
              },
            ],
          },
        ],
      },
      started: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      timeSpent: "1m",
    };

    try {
      await Jira.postResource(`issue/${FOUND_PROBLEMS_TASK}/worklog`, dto);
      return true;
    } catch (error) {
      console.error(error);
      notification.open({
        message:
          "Ha ocurrido un error al intentar loguear los problemas encontrados.",
        type: "error",
      });

      return false;
    } finally {
      this.setModalField("foundProblems", "loading", false);
    }
  };

  uploadNextDayTasks = async () => {
    const {
      modals: {
        nextDayTasks: {
          fields: { selection: nextDayTasks, text: nextDayDesc },
        },
      },
    } = this.state;
    let canContinue = true;

    this.setModalField("nextDayTasks", "loading", true);
    if (nextDayTasks.length === 0 && !nextDayDesc) {
      canContinue = await this.asyncConfirm({
        title: "No has imputado las tareas del proximo dia.",
        okText: "No tengo tareas para mi próximo dia laboral.",
        cancelText: "Cancelar",
      });
    }

    if (!canContinue) {
      return false;
    }

    let nextDayText =
      nextDayTasks.length === 0 && !nextDayDesc
        ? "No tengo tareas para mi proximo día laboral."
        : nextDayTasks.reduce(
          (prev: any, curr: any, index: number) =>
            prev + `\n${index + 1}. ${curr.key} - ${curr.fields.summary}`,
          ""
        );

    if (nextDayDesc) {
      nextDayText += `
            ${nextDayDesc}
            `;
    }
    let comment = Text.mdToJira(nextDayText)
      ? Text.mdToJira(nextDayText)
      : nextDayText;
    comment = comment.trim();
    let dto = {
      comment: {
        type: "doc",
        version: 1,
        content: [
          {
            type: "paragraph",
            content: [
              {
                text: comment,
                type: "text",
              },
            ],
          },
        ],
      },
      started: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
      timeSpent: "1m",
    };

    try {
      await Jira.postResource(`issue/${NEXT_DAY_TASK}/worklog`, dto);
      return true;
    } catch (error) {
      console.error(error);
      notification.open({
        message:
          "Ha ocurrido un error al intentar loguear las tareas del próximo día.",
        type: "error",
      });
      return false;
    } finally {
      this.setModalField("nextDayTasks", "loading", false);
    }
  };

  uploadInternalTasks = async () => {
    try {
      const loggedFoundProblems = await this.uploadFoundProblems();

      if (!loggedFoundProblems) {
        return false;
      }

      const loggedNextDayTasks = await this.uploadNextDayTasks();

      if (!loggedNextDayTasks) {
        return false;
      }

      this.setModalField("nextDayTasks", "selection", []);
      this.setModalField("foundProblems", "text", "");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  private renderNextDay(modals: any) {
    let {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      nextDayTasks: { visible, fields },
    } = modals;

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
      this.setModalField(
        "nextDayTasks",
        "selection",
        arrayMove(fields.selection, oldIndex, newIndex)
      );
    };

    const SortableItem = SortableElement(({ value, index }: any) => (
      <List.Item>
        {" "}
        <Typography.Text>
          {value.index}. {value.key} - {value.fields.summary}
        </Typography.Text>{" "}
      </List.Item>
    ));
    const SortableList = SortableContainer(({ items }: any) => {
      return (
        <List
          bordered
          dataSource={items}
          renderItem={(item: any, index) => (
            <SortableItem
              key={`ndt-${item.key}`}
              index={index}
              value={{ ...item, index: index + 1 }}
            />
          )}
          className="sortable-list"
        />
      );
    });

    return (
      <Panel
        header="Tareas del proximo día"
        key="nextDayTasks"
        extra={[
          <Tooltip title="Subir tareas del proximo día">
            <Button
              loading={fields.loading}
              icon={<CloudUploadOutlined />}
              shape="circle"
              className="flex-center"
              onClick={this.uploadNextDayTasks}
            />
          </Tooltip>,
        ]}
      >
        <Row gutter={4}>
          <Col span={24}>
            <Tooltip title="Actualizar">
              <Button
                className="flex-center"
                shape="circle"
                icon={<RedoOutlined />}
                onClick={() => this.refresh("nextDayTasks")}
              />
            </Tooltip>
          </Col>
          <Col span={24} className="mv16">
            <UserSelector
              project={"JIFC"}
              mode="search"
              value={fields.userSearchbox}
              onInputChanged={(text) => {
                this.setModalField("nextDayTasks", "userSearchbox", text);
                if (!text) {
                  this.setModalField("nextDayTasks", "selectedUser", null);
                }
              }}
              onSelect={(user) => {
                this.setModalField("nextDayTasks", "selectedUser", user);
                this.setModalField(
                  "nextDayTasks",
                  "userSearchbox",
                  user.displayName
                );
              }}
            />
          </Col>
          <Col span={12}>
            <IssueSelector
              mode="list"
              type="self"
              value={fields.searchboxValue}
              ref={this.nextDayIssueSelector}
              onInputChanged={(text) =>
                this.setModalField("nextDayTasks", "searchboxValue", text)
              }
              onMultipleSelectionChanged={(selectedIssues) =>
                this.setModalField("nextDayTasks", "selection", selectedIssues)
              }
              behaviour="available"
              multiple
              user={fields.selectedUser?.accountId}
              className="issue-selector"
            />
          </Col>
          <Col span={12}>
            <SortableList items={fields.selection} onSortEnd={onSortEnd} />
          </Col>
          <Col span={24} className="mt16">
            <TextArea
              placeholder={"Descripción (Opcional)"}
              onChange={(evt) =>
                this.setModalField("nextDayTasks", "text", evt.target.value)
              }
              value={fields.text || ""}
            />
          </Col>
        </Row>
      </Panel>
    );
  }

  private renderFoundProblems(modals: any) {
    let {
      foundProblems: {
        fields: { text, loading },
      },
    } = modals;

    return (
      <Panel
        key="foundProblems"
        header="Problemas encontrados"
        extra={[
          <Tooltip title="Subir problemas encontrados">
            <Button
              loading={loading}
              icon={<CloudUploadOutlined />}
              shape="circle"
              onClick={this.uploadFoundProblems}
            />
          </Tooltip>,
        ]}
      >
        <Row>
          <Col span={24} className="flex">
            <TextArea
              allowClear
              placeholder="Descripción (opcional)"
              value={text}
              className="flex1"
              onChange={({ target: { value } }) => {
                this.setModalField("foundProblems", "text", value);
              }}
            />
          </Col>
        </Row>
      </Panel>
    );
  }

  async createTask({
    epic,
    projectKey,
    summary,
    description,
    issueType = "1",
  }: any) {
    try {
      this.setState({ loading: true });
      let createdIssue = await Jira.postResource("issue", {
        fields: {
          summary,
          description: {
            type: "doc",
            version: 1,
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: description,
                    type: "text",
                  },
                ],
              },
            ],
          },
          customfield_10008: epic,
          project: {
            key: projectKey,
          },
          issuetype: {
            id: issueType && issueType !== "" ? issueType : "3",
          },
          assignee: {
            accountId: userStore.user.accountId,
          },
        },
      });

      createdIssue = await Jira.getResource(`issue/${createdIssue.key}`, "");

      this.setModalField("createTask", "summary", "");
      this.setModalField("createTask", "description", "");
      this.setModalField("createTask", "projectKey", "");
      this.setModalField("createTask", "epic", "");

      this.onSelectedIssue(createdIssue as Issue);

      this.mainIssueSelector.current?.updateEpics();

      this.toggleModal("createTask");
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Ha ocurrido un error al crear la tarea.",
        type: "error",
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  private renderMacrosModal(modals: any) {
    const {
      macros,
      modals: { macros: modal },
    } = this.state;
    const { fields } = modal;

    return (
      <Modal
        title="Insertar macro"
        visible={modal.visible}
        onOk={() => {
          this.toggleModal("macros");
        }}
        onCancel={() => this.toggleModal("macros")}
        closable
      >
        <Row gutter={12}>
          <Col span={fields.creating ? 24 : 12}>
            {!fields.creating ? (
              <List
                bordered
                dataSource={macros}
                footer={
                  <Button
                    onClick={() => {
                      this.setModalField("macros", "selectedMacro", null);
                      this.setModalField("macros", "creating", true);
                    }}
                  >
                    Crear macro
                  </Button>
                }
                renderItem={(item) => {
                  return (
                    <List.Item
                      onClick={() => {
                        this.setModalField("macros", "selectedMacro", item);
                      }}
                    >
                      {item.name}
                    </List.Item>
                  );
                }}
              />
            ) : (
              <Form
                onFinish={({ name }) => {
                  let { macros } = this.state;

                  macros.push({
                    id: this.state.macros.length,
                    name,
                    text: fields.createdMacroText,
                  });

                  this.setState({ macros });
                  localStorage.setItem("macros", JSON.stringify(macros));
                  this.setModalField("macros", "creating", false);
                }}
              >
                <Form.Item
                  name="name"
                  label="Nombre de la macro"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce un nombre para la macro.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <MdEditor
                    onChange={({ text, html }) => {
                      this.setModalField("macros", "createdMacroText", text);
                    }}
                    value={fields.createdMacroText || ""}
                    renderHTML={(text) => mdParser.render(text)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">Guardar macro</Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() =>
                      this.setModalField("macros", "creating", false)
                    }
                  >
                    Cancelar creación
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
          {fields.selectedMacro ? (
            <Col span={12}>
              <Input
                value={fields.selectedMacro.name}
                className="mb16"
                placeholder="Nombre de la macro"
                onChange={(evt) => {
                  let { selectedMacro } = fields;
                  selectedMacro.name = evt.target.value;
                  this.setModalField("macros", "selectedMacro", selectedMacro);
                }}
              />
              <MdEditor
                style={{ minWidth: "100%", margin: 6 }}
                onChange={({ text, html }) => {
                  let { selectedMacro } = fields;
                  selectedMacro.text = text;
                  this.setModalField("macros", "selectedMacro", selectedMacro);
                }}
                value={fields.selectedMacro?.text || ""}
                renderHTML={(text) => mdParser.render(text)}
              />
              <Button
                className="m16"
                onClick={() => {
                  this.setState(
                    {
                      macros: this.state.macros.map((i) => {
                        if (i.id === fields.selectedMacro.id) {
                          return fields.selectedMacro;
                        }

                        return i;
                      }),
                    },
                    () => {
                      localStorage.setItem(
                        "macros",
                        JSON.stringify(this.state.macros)
                      );
                      this.setModalField("macros", "selectedMacro", null);
                    }
                  );
                }}
              >
                Guardar
              </Button>
              <Button
                className="m6"
                onClick={() => {
                  this.setState({
                    worklogDescription: `${this.state.worklogDescription}\n${fields.selectedMacro.text}`,
                  });
                  this.toggleModal("macros");
                }}
              >
                Insertar macro
              </Button>
              <Button
                className="m6"
                onClick={() => {
                  this.setState({
                    macros: this.state.macros.filter(
                      (i) => i.id !== fields.selectedMacro.id
                    ),
                  });
                  this.setModalField("macros", "selectedMacro", null);
                  localStorage.setItem(
                    "macros",
                    JSON.stringify(this.state.macros)
                  );
                }}
              >
                Borrar macro
              </Button>
            </Col>
          ) : null}
        </Row>
      </Modal>
    );
  }

  /**
   * Render the new task modal
   * @param modals The modals to handle
   */
  private renderNewTaskModal(modals: any) {
    return (
      <Modal
        title="Crear tarea"
        visible={modals.createTask.visible}
        onOk={() => {
          const { loading } = this.state;

          if (loading) {
            notification.open({
              message:
                "La aplicación se encuentra en estado de carga. Espera a que la carga finalice para continuar.",
              type: "error",
            });
            return;
          }

          if (!modals.createTask.fields.summary) {
            notification.open({
              message: "Por favor, introduzca un titulo.",
              type: "error",
            });
            return;
          }

          if (!modals.createTask.fields.epic) {
            notification.open({
              message: "Por favor, introduzca una epic.",
              type: "error",
            });
            return;
          }
          this.createTask(modals.createTask.fields);
        }}
        onCancel={() => this.toggleModal("createTask")}
        closable
      >
        <Spin spinning={this.state.loading}>
          <Row>
            <p>Selecciona un proyecto y un epic</p>
          </Row>
          <Row>
            <Col span={24}>
              <ProjectSelector
                mode="search"
                placeholder="Selecciona un proyecto..."
                onSelect={(project) =>
                  this.setModalField("createTask", "projectKey", project.key)
                }
              />
            </Col>
          </Row>

          {modals.createTask.fields.projectKey && (
            <>
              <Row>
                <Col className="mt5" span={24}>
                  <IssueSelector
                    mode="search"
                    value={modals.createTask.fields.searchboxValue}
                    placeholder={"Epic link..."}
                    type="epics"
                    project={modals.createTask.fields.projectKey || ""}
                    onSelect={(epic) => {
                      this.setModalField("createTask", "epic", epic.key);
                      this.setModalField(
                        "createTask",
                        "searchboxValue",
                        epic.key
                      );
                    }}
                    onInputChanged={(text) =>
                      this.setModalField("createTask", "searchboxValue", text)
                    }
                  />
                </Col>
                <Col className="mt5" span={24}>
                  <Input
                    placeholder="Titulo de la tarea"
                    onChange={(evt) =>
                      this.setModalField(
                        "createTask",
                        "summary",
                        evt.target.value
                      )
                    }
                  />
                </Col>
                <Col className="mt5" span={24}>
                  <Select
                    className="w-full"
                    placeholder="Tipo de tarea"
                    onChange={(val) =>
                      this.setModalField("createTask", "issueType", val)
                    }
                  >
                    <Select.Option value="1">Error</Select.Option>
                    <Select.Option value="2">Nueva función</Select.Option>
                    <Select.Option value="3">Tarea</Select.Option>
                    <Select.Option value="4">Mejora</Select.Option>
                  </Select>
                </Col>
                <Col className="mt5" span={24}>
                  <TextArea
                    placeholder="Descripción de la tarea"
                    onChange={(evt) =>
                      this.setModalField(
                        "createTask",
                        "description",
                        evt.target.value
                      )
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </Modal>
    );
  }

  private canShowStatusSelector(selectedIssue: Issue) {
    if (this.state.isNew) {
      return (
        this.state.timeSpent &&
        this.state.worklogDescription !== "" &&
        ms(this.state.timeSpent)
      );
    }

    return selectedIssue.fields?.status?.id;
  }

  private getTotalTime(savedWorklogs: SavedWorklog[]) {
    //  prev + (curr.timeSpent && curr.timeSpent != null && curr.timeSpent !== '' ? ms(curr.timeSpent) : 0

    const cb = (prev: number, curr: SavedWorklog) => {
      try {
        if (curr.timeSpent) {
          // TODO: Refactor this
          if (curr.timeSpent.split(" ").length > 1) {
            const parsedTime = curr.timeSpent
              .split(" ")
              .reduce((prev, curr) => {
                return prev + ms(curr);
              }, 0);

            if (parsedTime && parsedTime > 0) {
              return prev + parsedTime;
            }
          } else {
            const parsedTime = ms(curr.timeSpent);

            if (curr.timeSpent && parsedTime && parsedTime > 0) {
              return prev + parsedTime;
            }
          }
        }
      } catch (err) {
        console.log("error parsing milliseconds. using zero instead.");
      }

      return prev + 0;
    };

    const sumTime: number = savedWorklogs.reduce(cb, 0);
    return sumTime ? prettyms(sumTime) : prettyms(0);
  }

  private renderWorklogEditor(
    worklogDescription: string,
    save?: (text: string) => void
  ): React.ReactNode {
    return (
      <Row className="worklog-editor-container">
        <Col span={24}>
          <MdEditor
            placeholder={"Descripción del worklog"}
            value={worklogDescription || ""}
            style={{ width: "100%", minHeight: "100%" }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ html, text }) =>
              !save ? this.setState({ worklogDescription: text }) : save(text)
            }
          />
        </Col>
        <Col className="mt16">
          <Button onClick={() => this.toggleModal("macros")}>Macros</Button>
        </Col>
      </Row>
    );
  }

  private renderIssueSelector(selectedIssue: Issue): React.ReactNode {
    const { selectedUser } = this.state;

    return (
      <IssueSelector
        onSelect={this.onSelectedIssue}
        exceptions={this.state.savedWorklogs.map(
          (issue) => issue.selectedIssue.key
        )}
        behaviour="available"
        mode="search"
        type="self"
        value={this.state.issueSearchbox}
        onInputChanged={(text) => {
          if (!text) {
            this.setState({ selectedIssue: {}, selectedEpic: {} });
          }
          this.setState({ issueSearchbox: text });
        }}
        user={selectedUser?.accountId}
        ref={this.mainIssueSelector}
      />
    );
  }

  private renderSavedWorklogs(savedWorklogs: SavedWorklog[]): React.ReactNode {
    return this.state.loading ? (
      <Spin spinning={true}>
        <Collapse bordered={false} defaultActiveKey={["0"]}>
          {savedWorklogs.map((wl, index) => this.renderSavedWorklog(index, wl))}
        </Collapse>
      </Spin>
    ) : (
      <Collapse bordered={false} defaultActiveKey={["0"]}>
        {savedWorklogs.map((wl, index) => this.renderSavedWorklog(index, wl))}
      </Collapse>
    );
  }

  private renderSavedWorklog(index: number, wl: SavedWorklog): JSX.Element {
    return wl.loading ? (
      <Spin spinning={true}>{this.renderWorklog(index, wl)}</Spin>
    ) : (
      this.renderWorklog(index, wl)
    );
  }

  private renderWorklog(index: number, wl: SavedWorklog): JSX.Element {
    return (
      <Panel
        key={`${index}`}
        header={`${wl.selectedIssue.key} - ${wl.timeSpent}`}
      >
        <Descriptions
          extra={[
            <Tooltip title="Subir solo este worklog">
              <Button
                className="mr6"
                onClick={() => this.uploadWorklog(wl, index)}
                icon={<CloudUploadOutlined />}
                shape="circle"
              />
            </Tooltip>,
            <Tooltip title="Eliminar del listado">
              <Button
                onClick={() => this.removeWorklogFromList(index)}
                icon={<DeleteOutlined />}
                shape="circle"
              />
            </Tooltip>,
          ]}
          bordered
        >
          <Descriptions.Item label="Titulo" span={3}>
            {wl.selectedIssue.fields.summary}
          </Descriptions.Item>
          <Descriptions.Item label="Texto" span={3}>
            {this.renderWorklogEditor(wl.worklogDescription, (text) => {
              wl.worklogDescription = text;
              this.forceUpdate();
            })}
          </Descriptions.Item>
          <Descriptions.Item label="Tiempo dedicado">
            <Input
              className="flush-ant-input"
              value={wl.timeSpent}
              onChange={(evt) => {
                wl.timeSpent = evt.target.value;
                this.forceUpdate();
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Tiempo estimado">
            {
              wl.selectedIssue.fields.original?.fields.timetracking
                ?.originalEstimate
            }
          </Descriptions.Item>

          <Descriptions.Item label="Transición">
            {wl.selectedIssue.fields?.status?.name}{" "}
            {wl.selectedTransition?.to
              ? `➞ ${wl.selectedTransition?.to?.name}`
              : ""}
          </Descriptions.Item>
        </Descriptions>
      </Panel>
    );
  }

  private removeWorklogFromList(index: number): void {
    let { savedWorklogs } = this.state;
    savedWorklogs.splice(index, 1);
    this.setState({ savedWorklogs });
  }
}
